import Actions from '@/components/actions';
import StyledImage from '@/components/styledImage';
import { mutateGraphQL } from '@/data/apollo';
import { InvoicesHouseAccountWrite, InvoicesWrite } from '@/data/commerce/invoice.graphql';
import { AddItemIdOnLineItems, ExportLineItemsCSV } from '@/data/management/lineItem.graphql';
import csvExport from '@/helpers/csvExport';
import currencyFormat from '@/helpers/currencyFormat';
import { syncToCloverAfterValidation } from '@/helpers/syncToClover';
import useAccountType from '@/helpers/useAccountType';
import useConfirmDialog from '@/hooks/useConfirmDialog';
import useGetDeviceInfo from '@/hooks/useGetDeviceInfo';
import { InvoicesReminderEmailModal } from '@/modals/email';
import HAMergeOptionsModal from '@/modals/HAMergeOptionsModal';
import MergeOptions from '@/modals/mergeOptionsModal';
import SelectStaffModal from '@/modals/selectStaff';
import {
	getHAGenerateInvoicesTotalAmount,
	groupPdfDownload,
} from '@/pages/dashboard/commerce/components/sharedActionUtils';
import { EmailAllModal } from '@/pages/dashboard/commerce/components/utils';
import { deleteMergedTo } from '@/pages/dashboard/commerce/invoices/actions/invoiceUtils';
import PaymentDrawer from '@/pages/dashboard/commerce/payment/paymentDrawer';
import {
	VerifyHouseAccountInvoicesModal,
} from '@/pages/dashboard/management/houseAccounts/form/modals/verifyHouseAccountInvoices';
import usePermissions, { permissions } from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { useEvents } from '@/providers/event';
import { useModal } from '@/providers/modal';
import {
	MutationAddItemIdOnLineItemsArgs,
	MutationInvoicesHouseAccountWriteArgs,
	MutationInvoicesWriteArgs,
	Order,
} from '@/types/schema';
import { isSuperAdmin } from '@/utils/isSuperAdmin';
import {
	AddCircle as AddCircleIcon,
	CallMerge as CallMergeIcon,
	CallSplit as CallSplitIcon,
	Delete as DeleteIcon,
	Edit as EditIcon,
	Email as EmailIcon,
	IosShareRounded as ExportIcon,
	Payment as PaymentIcon,
	PeopleAlt as PeopleAltIcon,
	PictureAsPdf as PictureAsPdfIcon,
} from '@mui/icons-material';
import { Theme, useMediaQuery } from '@mui/material';
import { isEmpty, toLower } from 'lodash-es';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import GenerateInvoice from '../../../management/houseAccounts/form/modals/generateInvoice';
import { stayOpen } from '../../components/tableHelpers';
import MultiPaymentsDrawer from '../../multiPayments/multiPaymentsDrawer';

export default function InvoicesMultiActions( {
	invoices,
	clearRows,
	selectedInvoices,
	month,
	allMonths,
	startOfWeek,
	endOfWeek,
	allWeeks,
}: {
	invoices: string[],
	clearRows: () => void,
	selectedInvoices: Order[] | undefined,
	month?: string,
	allMonths?: boolean,
	startOfWeek?: Date | null,
	endOfWeek?: Date | null,
	allWeeks?: boolean
} ) {
	const router = useRouter();
	const { showModal } = useModal();
	const { user } = useUserInfo();
	const confirmDialog = useConfirmDialog();
	const event = useEvents();
	const { isCloverDevice } = useGetDeviceInfo();
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const editable = usePermissions( permissions.invoices.write );
	const payable = usePermissions( permissions.payments.write );
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	const isMobile = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'sm' ) );
	const isIpad = useMediaQuery<Theme>( ( { breakpoints } ) => breakpoints.down( 'lg' ) );
	
	const isCloverAccount = useAccountType( 'CLOVER' );
	
	const superAdmin = isSuperAdmin( user );
	
	const selectedClient = selectedInvoices?.find( ( invoice ) => invoice.client )?.client;
	const hasSameClientId = selectedClient
		? selectedInvoices.every( ( invoice ) => invoice?.client?.id === selectedClient?.id )
		: [];
	const selectedClientWithEmail = selectedInvoices?.find( ( invoice ) => invoice.client?.email )?.client;
	
	const hasSameClientEmail = selectedClientWithEmail?.email && !isEmpty( selectedInvoices )
		? selectedInvoices?.every( ( invoice ) => toLower( invoice.client?.email || '' ) === toLower( selectedClientWithEmail.email ) ) ?? false
		: false;
	
	const selectedHouseAccount = selectedInvoices?.find( ( invoice ) => invoice.houseAccount )?.houseAccount;
	const hasSameHouseAccount = selectedHouseAccount && selectedInvoices?.every( ( invoice ) => invoice?.houseAccount?.id === selectedHouseAccount?.id );
	const allAccountInvoices = selectedInvoices?.every( ( invoice ) => invoice.status === 'ACCOUNT' );
	
	const selectedHouseAccountWithEmail = selectedInvoices?.find( ( invoice ) => invoice.houseAccount?.email )?.houseAccount;
	const hasSameHouseAccountEmail = selectedHouseAccountWithEmail?.email && !isEmpty( selectedInvoices )
		? selectedInvoices?.every( ( invoice ) => toLower( invoice.houseAccount?.email || '' ) === toLower( selectedHouseAccountWithEmail.email ) ) ?? false
		: false;
	
	const hasNoAccountStatus = selectedInvoices?.find( ( invoice ) => invoice.status !== 'ACCOUNT' );
	const hasAccountStatus = selectedInvoices?.find( ( invoice ) => invoice.status === 'ACCOUNT' );
	const hasAccountType = Boolean( selectedInvoices?.find( ( invoice ) => invoice.type === 'ACCOUNT' ) );
	const hasPartiallyPaid = Boolean( selectedInvoices?.find( ( invoice ) => invoice.status === 'PARTIALLY_PAID' ) );
	const paidInvoice = selectedInvoices?.find( ( invoice ) => [
		'CANCELLED',
		'PAID',
		'PENDING',
		'ACCOUNT' ].includes( invoice.status ) );
	
	const generatedInvoicesTotal = getHAGenerateInvoicesTotalAmount( selectedInvoices );
	if ( !selectedInvoices ) return null;
	
	return (
		<Actions
			separated
			max={isMobile || isCloverDevice || isIpad ? 4 : 8}
			items={[
				editable && payable && allAccountInvoices && {
					name   : `${t( 'common:pay' )} ${t( 'common:invoices' )} : ${currencyFormat( generatedInvoicesTotal )}`,
					icon   : <PaymentIcon/>,
					details: !hasSameHouseAccount
						? 'You must select invoices for the same house account to pay all at once.'
						: '',
					disabled   : !hasSameHouseAccount,
					buttonProps: {
						variant: 'outlined',
					},
					onClick: () => {
						
						const invoicesWithMergedTo = selectedInvoices?.filter( ( invoice ) => invoice.metadata?.mergedTo );
						if ( invoicesWithMergedTo && !isEmpty( invoicesWithMergedTo ) ) {
							showModal( VerifyHouseAccountInvoicesModal, { maxWidth: 'sm' }, {
								invoicesWithMergedTo,
								type    : 'Pay',
								onSubmit: () => showModal( MultiPaymentsDrawer, {
									variant: 'drawer',
									onClose: ( event, reason ) => stayOpen( event, reason ),
								}, { orders: selectedInvoices, houseAccount: selectedHouseAccount } ),
							} );
						} else {
							showModal( MultiPaymentsDrawer, {
								variant: 'drawer',
								onClose: ( event, reason ) => stayOpen( event, reason ),
							}, { orders: selectedInvoices, houseAccount: selectedHouseAccount } );
						}
						
					},
				}, editable && !hasNoAccountStatus && {
					name    : `${t( 'common:generate' )} ${t( 'common:invoice' )}: ${currencyFormat( generatedInvoicesTotal )}`,
					icon    : <AddCircleIcon/>,
					disabled: !hasSameHouseAccount,
					details : !hasSameHouseAccount
						? 'You must select invoices for the same house account to generate an invoice.'
						: 'Generate an invoice for all selected invoices',
					buttonProps: { variant: 'outlined' },
					onClick    : async () => {
						showModal( GenerateInvoice, { maxWidth: 'sm' }, {
							houseAccount: selectedHouseAccount,
							month,
							allMonths,
							selectedInvoices,
							startOfWeek : startOfWeek as any,
							endOfWeek   : endOfWeek as any,
							allWeeks,
							onGenerate  : async ( invoice ) => {
								await router.push( `/dashboard/commerce/invoices/${invoice?.id}` );
								clearRows();
							},
						} );
					},
				}, payable && !paidInvoice && {
					name   : t( 'common:pay' ),
					icon   : <PaymentIcon/>,
					details: !hasSameClientId
						? 'You must select invoices for the same client to pay all at once'
						: 'Pay all selected invoices',
					buttonProps: { variant: 'outlined', disabled: !hasSameClientId },
					onClick    : () => {
						if ( selectedInvoices?.length === 1 ) {
							showModal( PaymentDrawer, {
								variant: 'drawer',
								onClose: ( event, reason ) => stayOpen( event, reason ),
							}, { order: selectedInvoices[ 0 ] } );
						} else {
							return showModal( MultiPaymentsDrawer, {
								variant: 'drawer',
								onClose: ( event, reason ) => stayOpen( event, reason ),
							}, { orders: selectedInvoices, clearRows } );
						}
						
					},
				}, editable && invoices.length > 1 && {
					name       : t( 'commerce:merge' ),
					icon       : <CallMergeIcon/>,
					buttonProps: { disabled: hasPartiallyPaid || hasAccountType, variant: 'outlined' },
					details    : hasPartiallyPaid
						? 'Partially paid invoices cannot be merged.'
						: hasAccountType ? 'Account invoices cannot be merged.'
							: t( 'commerce:merge-multiple-invoices-into-one' ),
					onClick: () => {
						if ( hasAccountStatus ) {
							if ( !hasSameHouseAccount ) {
								enqueueSnackbar( t( 'commerce:select-invoices-house-account' ) );
								return;
							}
							showModal( HAMergeOptionsModal, { maxWidth: 'sm' }, {
								type            : 'Invoice',
								clearRows       : clearRows,
								selectedInvoices: selectedInvoices,
								houseAccount    : selectedHouseAccount,
								month,
								endOfWeek       : endOfWeek as any,
							} );
						} else {
							showModal( MergeOptions, { maxWidth: 'sm' }, {
								type             : 'Invoice',
								clearRows        : clearRows,
								commerces        : invoices,
								selectedCommerces: selectedInvoices,
							} );
						}
						
					},
				}, ( selectedInvoices?.length || 0 ) <= 50 && !hasAccountStatus && {
					name       : 'Email All',
					icon       : <EmailIcon/>,
					buttonProps: { variant: 'outlined' },
					details    : 'Each email will be sent individually',
					onClick    : async () => {
						showModal( EmailAllModal, { maxWidth: 'sm' }, {
							selectedInvoices,
							clearRows,
						} );
					},
				}, {
					name    : 'Send reminder',
					icon    : <EmailIcon/>,
					disabled: !hasSameClientEmail && !hasSameHouseAccountEmail,
					details : !hasSameClientEmail && !hasSameHouseAccountEmail
						? 'You must select invoices for the same client to send them a reminder.'
						: 'Send all invoices in one email',
					onClick: async () => showModal( InvoicesReminderEmailModal, {
						onClose: ( event, reason ) => stayOpen( event, reason ),
					}, {
						invoices: selectedInvoices,
						subject : 'Please view all your invoices',
						clearRows,
					} ),
				}, isCloverAccount && ( selectedInvoices?.length || 0 ) <= 25 && !hasAccountStatus && {
					name   : 'Sync to Clover',
					details: 'Create an order on your Clover account',
					icon   : <StyledImage
						alt='clover-pos-image'
						src='/images/clover-icon.png'
						width='20px'
						height='20px'
					/>,
					buttonProps: { variant: 'outlined' },
					onClick    : async () => {
						await syncToCloverAfterValidation( selectedInvoices );
						clearRows();
					},
				}, ( isOwner || isAdmin ) && {
					name   : t( 'commerce:edit-placed-by' ),
					icon   : <PeopleAltIcon/>,
					details: 'Select a staff member to edit the placed by field',
					onClick: () => showModal( SelectStaffModal, { maxWidth: 'sm' }, {
						type        : 'invoice',
						clearRows   : clearRows,
						selectedRows: invoices,
					} ),
				}, superAdmin && {
					name   : 'HA Id',
					icon   : <PeopleAltIcon/>,
					onClick: async () => {
						const accountInvoices = selectedInvoices?.filter( ( order ) => order.client && !order.houseAccount );
						if ( !accountInvoices || isEmpty( accountInvoices ) ) throw new Error( 'Select invoices with client id and no house account id' );
						
						const { invoicesHouseAccountWrite } = await mutateGraphQL<MutationInvoicesHouseAccountWriteArgs>( {
							mutation : InvoicesHouseAccountWrite,
							variables: { ids: accountInvoices.map( ( { id } ) => id ) },
						} );
						if ( invoicesHouseAccountWrite ) event.emit( 'reload.graphqlQuery', true );
						clearRows();
					},
				}, superAdmin && {
					name   : 'Add itemId',
					icon   : <EditIcon/>,
					onClick: async () => await mutateGraphQL<MutationAddItemIdOnLineItemsArgs>( {
						mutation : AddItemIdOnLineItems,
						variables: { orderIds: invoices },
					} ),
				}, isCloverAccount && superAdmin && {
					name: 'Sync & Hide',
					icon: <StyledImage
						alt='clover-pos-image'
						src='/images/clover-icon.png'
						width='20px'
						height='20px'
					/>,
					buttonProps: { color: 'primary' },
					onClick    : async () => {
						await syncToCloverAfterValidation( selectedInvoices, true );
						clearRows();
					},
				}, isCloverAccount && superAdmin && {
					name: 'Sync & Unhide',
					icon: <StyledImage
						alt='clover-pos-image'
						src='/images/clover-icon.png'
						width='20px'
						height='20px'
					/>,
					buttonProps: { color: 'primary' },
					onClick    : async () => {
						await syncToCloverAfterValidation( selectedInvoices, false );
						clearRows();
					},
				}, editable && {
					name   : t( 'commerce:download-pdf' ),
					icon   : <PictureAsPdfIcon/>,
					details: 'Download all selected invoices as a zip file',
					onClick: async () => {
						await groupPdfDownload( selectedInvoices, 'invoice' );
						clearRows();
					},
				}, editable && !hasAccountStatus && {
					name   : t( 'commerce:un-merge' ),
					icon   : <CallSplitIcon/>,
					onClick: async () => {
						await mutateGraphQL<MutationInvoicesWriteArgs>( {
							mutation : InvoicesWrite,
							variables: {
								method : 'Unmerged',
								options: { limit: invoices.length },
								ids    : invoices,
								input  : { merged: null },
							},
						} );
						clearRows();
					},
				}, superAdmin && {
					name       : t( 'common:export-line-items' ),
					icon       : <ExportIcon/>,
					buttonProps: { color: 'primary' },
					onClick    : async () => {
						const { data, filename } = await csvExport( {
							pageSize: 1000,
							filters : [ {
								id: 'order', value: { $in: invoices, temp: { checked: true } },
							} ],
						}, ExportLineItemsCSV, 'lineItems' );
						
						if ( data?.lineItemCSVString ) {
							const blob = new Blob( [ data.lineItemCSVString ], { type: 'text/csv;charset=utf-8;' } );
							const url = URL.createObjectURL( blob );
							const fixedEncodedURI = url.replace( /#/g, '%23' );
							const downloadLink = document.createElement( 'a' );
							downloadLink.href = fixedEncodedURI;
							downloadLink.download = filename;
							document.body.appendChild( downloadLink );
							downloadLink.click();
							document.body.removeChild( downloadLink );
						}
						clearRows();
					},
				}, editable && {
					name       : t( 'common:delete' ),
					icon       : <DeleteIcon/>,
					buttonProps: { color: 'error', variant: 'outlined' },
					onClick    : async () => {
						const value = await confirmDialog( {
							title  : t( 'common:delete' ),
							message: t( 'common:delete-confirmation' ),
						} );
						if ( !value ) return;
						await mutateGraphQL<MutationInvoicesWriteArgs>( {
							mutation : InvoicesWrite,
							variables: {
								method : 'Deleted Invoices',
								ids    : invoices,
								options: { limit: invoices.length },
								input  : { deletedAt: new Date() },
							},
						} );
						await deleteMergedTo( selectedInvoices );
						clearRows();
					},
				},
			]}
		/>
	);
}
